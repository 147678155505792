import React from 'react';
import CloseIcon from '@material-ui/icons/CloseSharp'; 
import MiradorMenuButton from 'mirador/dist/es/src/containers/MiradorMenuButton'; 
import ns from '../../config/css-ns'; 
import { removeWindow, removeManifest } from 'mirador/dist/es/src/state/actions';
import './close_remove_from_localstorage.css';

const xhttp = new XMLHttpRequest();
const LOCAL_STORAGE_ITEM = 'manifests';

const mapStateToProps = (state) => ({
  state: state,
});

const mapDispatchToProps = (dispatch, { manifestId, windowId }) => ({
    removeWindow: () => dispatch(removeWindow(manifestId, windowId)),
});

// checking existence of file synchronously
function doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    return xhr.status !== 404;
}


/**
 * on close window click
 * 
 * @param {*} props 
 * @returns 
 */
function CloseRemoveFromLocalStorageComponent(props) {
    const { state, windowId } = props;
    const manifestId = state.windows[windowId].manifestId;    
    const closeWinUpdateLocalStorage = () => {
        const storedManifests = getLocalStorage(LOCAL_STORAGE_ITEM);
        let storedManifestsArray = storedManifests.split(',');
        const manifestIndex = storedManifestsArray.indexOf(manifestId);
        
        storedManifestsArray.splice(manifestIndex, 1);
        
        // delete stored collection file
        xhttp.open("GET", "php/delete_file.php?file=../" + manifestId);
        xhttp.send();

        // remove the localStorage
        removeLocalStorage(LOCAL_STORAGE_ITEM);
        // console.log("REMOVED from LOCALSTORAGE", manifestId);

        // fill a new list for localStorage
        let manifestList='';
        for (let i = 0; i < storedManifestsArray.length; i++) {
            // console.log("storedManifestsArray[i]   ", storedManifestsArray[i]);
            if (storedManifestsArray[i].includes('collections/collection')) {
                if (! isEmpty(storedManifestsArray[i]) && doesFileExist(storedManifestsArray[i])) {
                    // console.log("STORED because file exists on server:", storedManifestsArray[i]);
                    manifestList += storedManifestsArray[i] + ",";
                }
            } else {
                manifestList += storedManifestsArray[i] + ",";
            }
        }

        // save in localStorage
        setLocalStorage(LOCAL_STORAGE_ITEM, manifestList); 
        // remove the window
        props.removeWindow("" + manifestId, "" + windowId);
    }
    
    const getLocalStorage = (item) => {
        return localStorage.getItem(item);
    }

    /**
     * complete remove localStorage
     * 
     * @param {*} item localStorageID 
     */
    const removeLocalStorage = (item) => {
        localStorage.removeItem(item.trim());
    }
    
    /**
     * fill localStorage
     * 
     * @param {*} item localStorageID
     * @param {*} content 
     */
    const setLocalStorage = (item, content) => {
        localStorage.setItem(item.trim(), content.trim());
    }

    function isEmpty(value) {
        return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
    }

    return (
        <MiradorMenuButton
            aria-label="Close Window"
            className={ns('window-close')}
            onClick={
                closeWinUpdateLocalStorage
            }
            ><CloseIcon />
        </MiradorMenuButton>
    )
}

export const CloseRemoveFromLocalStorage = {
  target: 'WindowTopBarPluginArea',
  mode: 'add',
  name: 'CloseRemoveFromLocalStorage',
  component: CloseRemoveFromLocalStorageComponent,
  mapStateToProps,
  mapDispatchToProps,
};
