import Mirador from 'mirador/dist/es/src/index';
//import React, { Component } from 'react';
import miradorImageToolsPlugin from 'mirador-image-tools/es/plugins/miradorImageToolsPlugin.js';
import miradorAnnotationPlugins from 'mirador-annotations/es/index';
import LocalStorageAdapter from 'mirador-annotations/es/LocalStorageAdapter';
import CustomBrand from './components/custom_brand';
import { CloseRemoveFromLocalStorage } from './components/close_remove_from_localstorage';
import ClearLocalStoragePlugin from './plugins/ClearLocalStoragePlugin';
import GithubLoginPlugin from './plugins/GithubLoginPlugin';
import MiradorSaIIIFrCollectionDialog from './plugins/MiradorSaIIIFrCollectionDialog';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

// Initial Viewer configuration
const config = {
    annotation: {
        adapter: (canvasIdz) => new LocalStorageAdapter(`localStorage://?canvasIdz=${canvasIdz}`),
        // adapter: (canvasId) => new AnnototAdapter(canvasId, endpointUrl),
    },
    id: 'mirador',
    language: 'de',
    window: {
        sideBarPanel: 'info', // Configure which sidebar is selected by default. Options: info, attribution, canvas, annotations, search
        sideBarOpen: false, 
        "imageToolsEnabled": true,
        "imageToolsOpen": false,
        allowClose: true, // Configure if windows can be closed or not
        // Set the default view type for all manifests without a viewingHint
        defaultView: 'single', // options: single, book, gallery, scroll
        views: [ // This is a copy of ProjectMirador/mirador's src/config/settings.js
            { key: 'single' },
            // { key: 'single', behaviors: ['individuals'] },
            { key: 'book', behaviors: ['paged'] },
            { key: 'gallery' },
        ],
    },
    windows: [],
    panels: { // Configure which panels are visible in WindowSideBarButtons
        info: true,
        // attribution: true,        
        // canvas: true,
        annotations: true, // still getting an error on the annotations button
        // search: true,
        // layers: false,
        layers: true,
      },
    // These manifests are available in the catalog by default
    catalog: [ ],  
    selectedTheme: 'ZBTheme',
    themes:  {
        ZBTheme: {
            palette: {
                primary: {                    
                    main: '#846963',  //ZB-braun
                    dark: '#66514d',
                },
                secondary: {
                    main: '#5574bb', // ZB-blau
                    dark: '#415fa4',
                }
            }
        }
    },    
    user: null,
    gistWritten: false,
};

const plugins = [
    {
        mode: 'wrap',
        component: CustomBrand,
        target: 'Branding',
    },
    {
        mode: 'wrap',
        component: MiradorSaIIIFrCollectionDialog,
        target: 'CollectionDialog',
        // ToDo How can we use this?
        mapDispatchToProps: (dispatch, { filterText }) => ({
            // ToDo
        }),
        mapStateToProps: (state, props) => {
            return {
                //collectionIsSaved: config.user !== undefined,
                //gistWrittenOutput: config.gistWritten !== false ? 'saved' : 'save',
                //gistWritten: config.gistWritten,
            }
        },
    },
    ClearLocalStoragePlugin,
    miradorImageToolsPlugin,
    miradorAnnotationPlugins,
    GithubLoginPlugin,
];

/**
 * function that copies the by default defined catalogs
 */
/*const copyCatalogStandardsToWindows = () => {
    const catalogData = config.catalog;
    for (var i = 0; i < catalogData.length; i++) {
        config.windows.push( catalogData[i] );
    }
}*/

// are there already manifests saved in localStorage? 
var manifestStorage = localStorage.getItem('manifests');
// empty to pass stored data to
var manifestStorageArray = []; 

// already existing manifests
const getCurrentManifests = () => {
    return manifestStorage.split(",");
}

const notEmpty = (value) => {
    return typeof value == 'string' && value.trim().length > 0;
}

/**
 * checking if file exists
 *  */ 
function doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    return xhr.status !== 404;
}

/**
 * add localStorage elements to manifestStorageArray
 */
function fillWithLocalStorageManifests() {
    console.log("fillWithLocalStorageManifests");
    if (manifestStorage) {
        let currentManifests = getCurrentManifests();
        for (var i = 0; i<currentManifests.length; i++) {
            const value = currentManifests[i];
            if (notEmpty(value)) {
                console.log("CURRENT MANIFEST IN LOCALSTORAGE", value);
                if (value.includes('collections/collection')) {
                    // TEST IF FILE STILL EXISTS IN CATALOG FOLDER
                    // console.log("collection file still exists: ", doesFileExist(value));
                    if (doesFileExist(value)) {
                        manifestStorageArray.push(value);
                    }
                } else {
                    manifestStorageArray.push(value);
                }
            }
        }
    }
}
    
/**
 *  update windows config with elements from manifestStorageArray
 */
function updateWindowsConfigWithArrayElements() {
    console.log("updateWindowsConfigWithArrayElements");
    for (var i=0; i < manifestStorageArray.length; i++) {
        var o = {};
        o.imageToolsEnabled= true,
        o.imageToolsOpen= false,
        o.manifestId = manifestStorageArray[i];
        config.windows.push( o, );
    }
}

/** 
 * Function to update manifest config file
 * */ 
export function updateViewerContent(windowsData, collectionData, sameViewer, selectedLanguage) {
    let getDataCount = windowsData.length;
    let postDataCount = collectionData.length;
    console.log("updating viewer content", getDataCount, postDataCount);

    if (sameViewer === false && postDataCount === 0) { 
        // REMOVE LOCALSTORAGE
        localStorage.removeItem('manifests');
        console.log("removed localStorage");
    } else {
        // disable standard window close icon
        config.window.allowClose = false; 

        // GET-Request
        if (getDataCount > 0) {
            if (manifestStorage && sameViewer===true) {
                fillWithLocalStorageManifests();
                // add given manifest to array
                for (var i=0; i<getDataCount; i++) {
                    const value = windowsData[i].manifestId;
                    if (notEmpty(value)) {
                        // console.log("NEW MANIFEST", value);
                        manifestStorageArray.push(value);
                    }
                }
            } else {
                // add first manifest to array
                let firstManifest = windowsData[0];
                //console.log("FIRST MANIFEST", firstManifest);
                manifestStorageArray.push(firstManifest.manifestId.trim());
            }

            updateWindowsConfigWithArrayElements();
        } 
        else {
            console.log("No GET Data send");
        }
        
        // POST-Request
        if (postDataCount > 0) {
            console.log("postDataCount", postDataCount);
            console.log("collectionData", collectionData);
            // Update config windows with given collection
            config.windows.push( collectionData[0]);
            // console.log("POST!",collectionData);
            for (var i = 1; i < collectionData.length; i++) {
                config.catalog.push( collectionData[i] );
            }
        }
        else {
            console.log("No POST Data send");
        }

        if (postDataCount==0 && getDataCount==0) {
            // console.log("no GET, no POST but probably LocalStorage")
            fillWithLocalStorageManifests();
            updateWindowsConfigWithArrayElements();
        }

        // in same viewer show close icon from CloseRemoveFromLocalStorage plugin
        if (sameViewer === true || postDataCount > 0) {
            plugins.push(CloseRemoveFromLocalStorage);
        }

        // set language
        if (selectedLanguage === 'de' || selectedLanguage === 'en') {
            config.language = selectedLanguage;
        }
    }

    // SHOW VIEWER
    Mirador.viewer(config, plugins);
};
