import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import "./GithubLogin.css";
// miradorviewer-zb
//const GITHUB_CLIENT_ID = "3f5d38d7c7b941396b0d";
//const gitHubRedirectURL = "http://localhost:4000/api/auth/github";

// OAuth: saIIIFrBetaViewerZbUzh
const GITHUB_CLIENT_ID = "289a3584e408445b3c32"; 
const gitHubRedirectURL = "https://beta.viewer.zb.uzh.ch/api/auth/github";

const path = "/";

function GithubLogin() {
  const [user, setUser] = useState();

  useEffect(() => {
    (async function () {
      const usr = await axios
          // miradorviewer-zb
          //.get(`http://localhost:4000/api/me`, {
          // beta.viewer.zb.uzh.ch
          .get(`https://beta.viewer.zb.uzh.ch/api/me`, {
          withCredentials: true,
        })
        .then((res) => res.data);
        /*.then((res) => {
          console.log("RESULT: ", res)
        });*/

      setUser(usr);
    })();
  }, []);

  const logoutUser = () => {
    console.log("clicked logoutUser!");
    if (document.cookie.split(";").some((item) => item.trim().startsWith("github-jwt="))) {
      document.cookie = "github-jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setTimeout(()=> { location.reload(); }, 400)
    }
  }

  return (
    <div className="App">
      {!user ? (
        <a
          /**
           * Scopes for user: 
           *  - user
           *  - gist
           * https://docs.github.com/en/developers/apps/building-oauth-apps/scopes-for-oauth-apps
           *  
           * TODO auch ohne redirect_uri möglich?
           * */ 
          href={`https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&redirect_uri=${gitHubRedirectURL}?path=${path}&scope=user:email%20gist%20repo%20public_repo,notifications`} 
        >
          {/* <img src='/images/Octocat.png'  */}
          <img src='assets/img/octocat.svg'
            title='Login for saving'
            alt='Github Login' 
            className="github-logo--login" />
          {/* LOGIN WITH GITHUB */}
        </a>
      ) : (
        <div>
          {/* FIX because not  able to use state*/}
          <p className="logged-in"><span id="user--name">{user.login}</span></p>

          <img src='assets/img/logout-icon.png'
            onClick={ logoutUser }
            id="logout-button"
            title='Logout'
            alt='Github Logout' 
            className="github-logo--logout" />
        </div>
      )}
  </div>
  );
};

// const LoginPlugin = {
//     target: 'WorkspaceControlPanelButtons',
//     mode: 'add',
//     name: 'GithubLoginPlugin',
//     component: GithubLogin,
// };

// export default LoginPlugin;

export default {
  target: 'WorkspaceControlPanelButtons',
  mode: 'add',
  name: 'GithubLoginPlugin',
  component: GithubLogin
};
