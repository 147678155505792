import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  MenuList,
  MenuItem,
  Typography,
} from '@material-ui/core';
// import { MDCSnackbar } from '@material/snackbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import Skeleton from '@material-ui/lab/Skeleton';
// import asArray from '../lib/asArray';
import { LabelValueMetadata } from 'mirador/dist/es/src/components/LabelValueMetadata';
import CollapsibleSection from 'mirador/dist/es/src/containers/CollapsibleSection';
import ScrollIndicatedDialogContent from 'mirador/dist/es/src/containers/ScrollIndicatedDialogContent';
import ManifestInfo from 'mirador/dist/es/src/containers/ManifestInfo';
import { withPlugins } from 'mirador/dist/es/src/extend/withPlugins';
import "../components/CollectionDialogZB.css";
import "./MiradorSaIIIFrCollectionDialog.css";

var saveButton;
/*
* Generate random 'state' string
*/
var randomString = getRandomString(25, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

/*
* CHANGE CLIENT
* uncomment authURL
*/

// OAuth: saIIIFrBetaViewerZbUzh
var clientId_extern = '289a3584e408445b3c32';
// localhost miradorviewer-zb 
//var clientId_extern = '3f5d38d7c7b941396b0d'; 

var authURL = "https://github.com/login/oauth/authorize?scope=read:user%20gist&client_id=".concat(clientId_extern, "&state=", getRandomString);
/* END CHANGE CLIENT */


function reqListener() {
  console.log("RESPONSETEXT: ", this.responseText);
}

function getRandomString(length, chars) {
  var result = '';
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

function setCookie(cName, cValue, expMin) {
  let date = new Date();
  // TODO don't use 120 to get my correct local time
  date.setTime(date.getTime() + ((120 + expMin) * 60000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=";
}

function asArray(value) {
  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
}

/**
 * load collection JSON
 * store temporarily to localStorage
 * 
 * @param {*} url 
 */
function loadJson(url) {
  var xhr = new XMLHttpRequest();
  xhr.addEventListener("load", reqListener);
  xhr.onload = function () {
    //console.log("response: ", xhr.response);
    var json = JSON.parse(xhr.response);
    //console.log("JSON",json);
    localStorage.setItem('collectionContent', xhr.response);
    console.log("JSON stored in localStorage");
  }
  xhr.open("GET", url, true);
  xhr.send();
}



function snackbar(state) {
  const snackbar = document.getElementById("snackbar");
  snackbar.classList.remove('yes', 'no');
  snackbar.classList.add('show');
  if (state == true) {
    snackbar.classList.add('yes');
  }
  else if (state == false) {
    snackbar.classList.add('no');
  }
  else {
    snackbar.classList.remove('show');
  }

}

function showSavingState(state) {
  const saveButton = document.getElementById("button-save-collection");
  if (state === true) {
    saveButton.classList.add("disabled");
    saveButton.innerText = "saving";
  } else {
    saveButton.classList.remove("disabled")
    saveButton.innerText = "save collection";
  }
}

function changeButtonState(state) {
  const saveButton = document.getElementById("button-save-collection");
  if (state === true) {
    saveButton.classList.remove("collection-dialog--button--save-false")
    saveButton.classList.add("collection-dialog--button--save-true")
  } else {
    saveButton.classList.remove("collection-dialog--button--save-true")
    saveButton.classList.add("collection-dialog--button--save-false")
  }
}

function showCollectionUrl(url) {
  document.getElementById("collection-dialog-output").innerHTML = "<a href='" + decodeURIComponent(url) + "' target='_blank'>show saved collection</a>";
}

/**
 * 
 * @returns checks if user is logged in, a must have to save collections  
 */
function checkUserIsLoggedIn() {
  saveButton = document.getElementById("button-save-collection");
  if (null != document.getElementById("user--name")) {
    console.log("logged in");
    if (null != saveButton) {
      saveButton.classList.remove("disabled")
      return true;
    }
  }
  console.log("not logged in yet");
  if (null != saveButton) {
    saveButton.classList.add("disabled")
  }
  setTimeout(checkUserIsLoggedIn, 1000);
  return false;
}

/**
 * 
 * @param {open the given collection in collection modus} winId 
 */
const openCollectionModus = (winId) => {
  console.log("winId", winId);
  console.log("WINDOW", document.getElementById(winId));
  console.log("open collections first manifest to close collection dialog");

  document.getElementById(winId).getElementsByClassName('MuiListItem-button')[0].click();
  setTimeout(() => {
    // open window sidebar panel
    // document.getElementById(winId).querySelector('[aria-label="Toggle sidebar"]').click();
    document.getElementById(winId).querySelector('.mirador-window-top-bar button:first-of-type').click();
    setTimeout(() => {
      // open manifest items list in left sidebar to get the button to open the right sidebar for later use 
      document.getElementById(winId).querySelector('[aria-label="Index"]').click();
      setTimeout(() => {
        // open the right sidebar
        document.getElementById(winId).querySelector('.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-fullWidth').click();
        setTimeout(() => {
          // console.log("reset left sidebar to show metadata: info");
          document.getElementById(winId).querySelector('.MuiTabs-scroller button:first-of-type').click();
        }, 300);
      }, 300);

    }, 300);

  }, 100);

}

/*
  to dispatch action types and windowId's to miradorSaIIIFrPlugin
 */
const mapDispatchToProps = (dispatch, { windowId }) => ({
  // called onClick()
  closeDialog: () => dispatch({ type: 'CLOSE_WINDOW_DIALOG', windowId }),
  createPage: () => dispatch({ type: 'SAVE_TO_REPO', windowId })
});

/**
 * a dialog providing the possibility to save the collection or open it in collection mode
 */
export default class MiradorSaIIIFrCollectionDialog extends Component {
  /** */
  startCollectionModus(winId) {
    console.log("testing startCollectionModus");
    openCollectionModus(winId);
  }

  /** */
  static getUseableLabel(resource, index) {
    return (resource
      && resource.getLabel
      && resource.getLabel().length > 0)
      ? resource.getLabel().getValue()
      : String(index + 1);
  }

  /** */
  constructor(props) {
    super(props);

    console.log("MOIN, MiradorSaIIIFrCollectionDialog am Start");

    console.log(checkUserIsLoggedIn() ? "User" : "anonym");

    this.state = {
      filter: null,
      user: null,
      // isSaved: false,
      // TODO: setState...
      // collectionIsSaved: false,
    };
    this.hideDialog = this.hideDialog.bind(this);
    this.collectionModus = this.collectionModus.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  /*
    TODO should be done with a callback after saving?
    https://docs.github.com/en/rest/reference/gists
    this.setSavedStatus(this.props.isSaved);
  */

  /** */
  setFilter(filter) {
    this.setState({ filter });
  }

  /** */
  hideDialog() {
    const {
      hideCollectionDialog, windowId,
    } = this.props;

    hideCollectionDialog(windowId);
  }


  /** */
  onSaveClick() {
    const {
      saveCollection, windowId,
    } = this.props;

    console.log('save clicked: ', windowId);
    saveCollection(windowId);
  }

  /** */
  collectionModus() {
    const {
      openCollectionModus, windowId,
    } = this.props;

    // hier wird die windowId weitergereicht
    this.startCollectionModus(windowId);
  }

  /** */
  selectCollection(c) {
    const {
      collectionPath,
      manifestId,
      showCollectionDialog,
      windowId,
    } = this.props;

    showCollectionDialog(c.id, [...collectionPath, manifestId], windowId);
  }

  /** */
  goToPreviousCollection() {
    const { collectionPath, showCollectionDialog, windowId } = this.props;

    showCollectionDialog(
      collectionPath[collectionPath.length - 1],
      collectionPath.slice(0, -1),
      windowId,
    );
  }

  /** */
  selectManifest(m) {
    const {
      addWindow,
      collectionPath,
      manifestId,
      setWorkspaceAddVisibility,
      updateWindow,
      windowId,
    } = this.props;

    if (windowId) {
      updateWindow(windowId, {
        canvasId: null, collectionPath: [...collectionPath, manifestId], manifestId: m.id,
      });
    } else {
      addWindow({ collectionPath: [...collectionPath, manifestId], manifestId: m.id });
    }

    this.hideDialog();
    setWorkspaceAddVisibility(false);
  }

  /** */
  dialogContainer() {
    const { containerId, windowId } = this.props;
    // get JSON content
    console.log('collectionUrl', this.props.manifestId);
    loadJson(this.props.manifestId);

    return document.querySelector(`#${containerId} #${windowId}`);
  }

  /** */
  placeholder() {
    const { classes } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        onClose={this.hideDialog}
        open
        container={this.dialogContainer()}
        BackdropProps={this.backdropProps()}
      >
        <DialogTitle id="select-collection" disableTypography>
          <Skeleton className={classes.placeholder} variant="text" />
        </DialogTitle>
        <ScrollIndicatedDialogContent>
          <Skeleton className={classes.placeholder} variant="text" />
          <Skeleton className={classes.placeholder} variant="text" />
        </ScrollIndicatedDialogContent>
      </Dialog>
    );
  }

  /** */
  backdropProps() {
    const { classes } = this.props;
    return { classes: { root: classes.dialog } };
  }

  /** */
  render() {
    const {
      classes,
      collection,
      error,
      isMultipart,
      manifest,
      ready,
      t,
    } = this.props;

    const { filter } = this.state;

    if (error) return null;
    // If this component is optimistically rendering ahead of the window its in
    // force a re-render so that it is placed correctly. The right thing here is
    // to maybe pass a ref.
    if (!this.dialogContainer()) {
      this.forceUpdate();
      return <></>;
    }
    if (!ready) return this.placeholder();

    const rights = manifest && (asArray(manifest.getProperty('rights') || manifest.getProperty('license')));

    const propRights = manifest.getProperty('rights');
    const propLicense = manifest.getProperty('license');

    const requiredStatement = manifest
      && asArray(manifest.getRequiredStatement()).filter(l => l.getValue()).map(labelValuePair => ({
        label: null,
        values: labelValuePair.getValues(),
      }));

    const collections = manifest.getCollections();

    const currentFilter = filter || (collections.length > 0 ? 'collections' : 'manifests');

    const onSaveToRepoClick = () => {
      console.log("Plugin clicked to save (Frontend)");

      // date-time string from the moment clicked
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      const dateTimeString = today.toISOString();

      showSavingState(true);
      /*
      * set state cookie for 10 minutes.
      * This will be read for identification on redirect call.
      */
      setCookie('savePage', getRandomString, 600); // TODO change back to 10 minutes

      const collectionContent = encodeURIComponent(localStorage.getItem('collectionContent'));
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("load", reqListener);

      // OAuth: beta.viewer.zb.uzh.ch
      xhr.open("GET", "https://beta.viewer.zb.uzh.ch/api/github/new_repo?content="+collectionContent+"&now="+dateTimeString);

      // localhost miradorviewer-zb
      // xhr.open("GET", "http://localhost:4000/api/github/new_repo?content=" + collectionContent + "&now=" + dateTimeString);
      
      xhr.send();
      //xhr.onload = function() {
      xhr.onreadystatechange = function () {
        /**
         * Outputs the following:
         *
         * UNSENT: 0
         * OPENED: 0
         * LOADING: 200
         * DONE: 200
         */
        console.log(":::::::::::::::::::::::::::::");
        console.log("----- XHR.STATUS -----", xhr.status);
        console.log(":::::::::::::::::::::::::::::");
        localStorage.removeItem('collectionContent');
        console.log('removed from LocalStorage');


        if (!xhr.status >= 200 && xhr.status < 400) { // analyze HTTP status of the response
          snackbar(false);
          //changeButtonState(false)
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { // show the result
          console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response
          const userName = document.getElementById("user--name").innerText;
          console.log("username is " + userName);
          snackbar(true);
          showCollectionUrl("https://raw.githubusercontent.com/" + userName + "/My-IIIF-Collections/main/collections/my_collection-" + dateTimeString + ".json");
        }
        showSavingState(false);
        changeButtonState(false);
      };
    };

    return (
      <Dialog
        className={classes.dialog}
        onClose={this.hideDialog}
        container={this.dialogContainer()}
        BackdropProps={this.backdropProps()}
        open
      >
        <div id='snackbar'></div>

        <DialogTitle id="select-collection" disableTypography>
          {/* <Typography component="div" variant="overline">
              { t(isMultipart ? 'multipartCollection' : 'collection') }
            </Typography> */}
          <Typography variant="h3">
            {MiradorSaIIIFrCollectionDialog.getUseableLabel(manifest)}
          </Typography>
        </DialogTitle>
        <ScrollIndicatedDialogContent className={classes.dialogContent}>
          {collection && (
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => this.goToPreviousCollection()}
            >
              {MiradorSaIIIFrCollectionDialog.getUseableLabel(collection)}
            </Button>
          )}

          {currentFilter === 'collections' && (
            <MenuList>
              {
                collections.map(c => (
                  <MenuItem
                    key={c.id}
                    onClick={() => { this.selectCollection(c); }}
                    className={classes.collectionItem}
                  >
                    {MiradorSaIIIFrCollectionDialog.getUseableLabel(c)}
                  </MenuItem>
                ))
              }
            </MenuList>
          )}
          {currentFilter === 'manifests' && (
            <MenuList>
              {
                manifest.getManifests().map(m => (
                  <MenuItem
                    key={m.id}
                    onClick={() => { this.selectManifest(m); }}
                    className={classes.collectionItem}
                  >
                    {MiradorSaIIIFrCollectionDialog.getUseableLabel(m)}
                  </MenuItem>
                ))
              }
            </MenuList>
          )}

          <div className="{classes.collectionFilter} collection-dialog--info-totals">
            <span className='collection-dialog-totals'>
              {manifest.getTotalCollections() > 0 && (
                // <Chip clickable color={currentFilter === 'collections' ? 'primary' : 'default'} onClick={() => this.setFilter('collections')} label={t('totalCollections', { count: manifest.getTotalCollections() })} />
                <strong color={currentFilter === 'collections' ? 'primary' : 'default'}>
                  {t('totalCollections', { count: manifest.getTotalCollections() })}
                  {/* { manifest.getTotalCollections() } items */}
                </strong>
              )}
              {manifest.getTotalManifests() > 0 && (
                // <Chip clickable color={currentFilter === 'manifests' ? 'primary' : 'default'} onClick={() => this.setFilter('manifests')} label={t('totalManifests', { count: manifest.getTotalManifests() })} />
                <strong color={currentFilter === 'manifests' ? 'primary' : 'default'}>
                  {/* {t('totalManifests', { count: manifest.getTotalManifests() })} */}
                  {manifest.getTotalManifests()} items
                </strong>
              )}
            </span>
          </div>

          {/* 
              `We` are not showing collection metadata in this dialog because it's currently quite not serviceable
            
            <div className={classes.collectionMetadata}>
              <ManifestInfo manifestId={manifest.id} />
              { propRights && (
              <CollapsibleSection
                  id="select-collection-rights"
                  label={t('attributionTitle')}
              >
                { requiredStatement && (
                    <LabelValueMetadata labelValuePairs={requiredStatement} defaultLabel={t('attribution')} />
                )}
                {
                  rights && rights.length > 0 && (
                      <>
                        <Typography variant="subtitle2" component="dt">{t('rights')}</Typography>
                        { rights.map(v => (
                            <Typography variant="body1" component="dd" key={v}>
                              <Link target="_blank" rel="noopener noreferrer" href={v}>
                                {v}
                              </Link>
                            </Typography>
                        )) }
                      </>
                  )
                }
              </CollapsibleSection>
              )}
            </div> */}
        </ScrollIndicatedDialogContent>
        <DialogActions className="collection-dialog--container">
          <Button className="collection-dialog--button collection-dialog--button--close" onClick={this.hideDialog}>
            {t('close')}
          </Button>
          {/* <Button className="collection-dialog--button collection-dialog--button--open" onClick={onOpenClick(0)}> */}
          <Button className="collection-dialog--button collection-dialog--button--open" onClick={this.collectionModus}>
            {t('open collection')}
          </Button>
          <Button id="button-save-collection" className={this.props.isSaved === true
            ? "collection-dialog--button collection-dialog--button--save disabled"
            //? "collection-dialog--button collection-dialog--button--save collection-dialog--button--save-true"
            : "collection-dialog--button collection-dialog--button--save active disabled"
            //: "collection-dialog--button collection-dialog--button--save collection-dialog--button--save-false active"
            //} onClick={ this.onSaveClick }>
            //} onClick={ onPagesClick() }>
          } onClick={() => onSaveToRepoClick()} >
            {t('save collection')}
            {/* {t(this.props.isSavedOutput)} */}
          </Button>
          <div>{ }</div>
        </DialogActions>
        <div id='collection-dialog-output'>&nbsp;</div>
      </Dialog>
    );
  }
}

MiradorSaIIIFrCollectionDialog.propTypes = {
  addWindow: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  collection: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  collectionPath: PropTypes.arrayOf(PropTypes.string),
  containerId: PropTypes.string,
  error: PropTypes.string,
  hideCollectionDialog: PropTypes.func.isRequired,
  collectionModus: PropTypes.func.isRequired,
  saveCollection: PropTypes.func.isRequired,
  createRepo: PropTypes.func.isRequired,
  createPage: PropTypes.func.isRequired,
  isMultipart: PropTypes.bool,
  manifest: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  manifestId: PropTypes.string.isRequired,
  ready: PropTypes.bool,
  setWorkspaceAddVisibility: PropTypes.func.isRequired,
  showCollectionDialog: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateWindow: PropTypes.func.isRequired,
  windowId: PropTypes.string,
  collectionIsSaved: PropTypes.bool,
  isSaved: PropTypes.bool,
};

MiradorSaIIIFrCollectionDialog.defaultProps = {
  collection: null,
  collectionPath: [],
  containerId: null,
  error: null,
  isMultipart: false,
  ready: false,
  windowId: null,
  collectionIsSaved: false,
  isSaved: false,
};