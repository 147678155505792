import React, { Component } from 'react';
import "./ClearLocalStorage.css";

const redirectURL = "index.php?same_viewer=false";


class ClearLocalStoragePlugin extends Component {
  xhttp = new XMLHttpRequest;
  removeOldCollectionFiles = () => {
    // delete stored collection file older than ...
    this.xhttp.open("GET", "php/delete_old_collection_files.php");
    this.xhttp.send();
    return Promise.resolve(this.xhttp.status);
  };
  
  onClickHandler = () => {
    this.removeOldCollectionFiles()
      .then((res) => {
        console.log("result", res)
      })  
      .then(() => {
        window.open(redirectURL,'_self')
      })
      .catch((e) => {
        console.log("error", e);
      })
  }

  render() {    
    return (
      <div className="clear-localstorage--container">
        <a onClick={(e) => {
          e.preventDefault();
          this.onClickHandler();
          // window.open(redirectURL);
        }}
        >
          <img src='./assets/img/trashcan-black.png' 
            title='Remove all Manifests'
            alt='Remove all Manifests' 
            className="clear-localstorage--icon" />
        </a>
      </div>
    );
  }
}

export default {
  target: 'WorkspaceControlPanelButtons',
  mode: 'add',
  component: ClearLocalStoragePlugin,
}